<template>
  <div class="navbar-container bg p-0">
    <div class="navbar-container d-flex content align-items-center">
      <!-- Nav Menu Toggler -->

      <div>
        <i
          class="icon fad fa-bell fa-2x"
          style="color: #fff"
        />
      </div>

      <div class="m-auto">
        <a href="/">
          <!-- <b-img
            src="logo/LToTHAI.png"
            height="30"
          /> -->
        </a>
      </div>

      <div>
        <router-link
          :to="{ name: 'profile' }"
          style="color: #000"
        >
          <i

            class="icon fad fa-address-card fa-2x"
          />
        </router-link>
      </div>

      <b-modal
        id="modal-xl"
        hide-footer
        centered
        size="xs"
        title="Extra Large Modal"
        hide-header
      >
        <div class="text-center">
          <router-link :to="{ name: 'auth-login' }">
            <b-button
              variant="gradient-warning"
              pill
              @click="logout"
            >
              <feather-icon
                icon="LogInIcon"
                class="mr-50"
              />
              <span class="align-middle">ออกจากระบบ</span>
            </b-button>
          </router-link>
        </div>
      </b-modal>
    </div>

    <!-- Nav Menu Toggler -->
    <div class="app__announce">
      <div class="d-flex container-fluid p-0">
        <div class="announce-wrapper">
          <div class="announce-icon">
            <i class="icon fad fa-bullhorn" />
          </div>
          <div class="announce-text">
            <div class="marquee-text-wrap">
              <div class="marquee-text-content">
                <div
                  class="marquee-text-text"
                >
                  <marquee
                    behavior=""
                    direction=""
                  >
                    ยินดีต้อนรับสมาชิกเก่าและสมาชิกใหม่ สู่การอัพเกรดไปอีกขั้น ของวงการเว็บหวยออนไลน์ ด้วยการพัฒนาดีไซน์และรูปแบบการเล่นที่ทันสมัยรวดเร็วและตอบสนองการใช้งานได้มากกว่า ด้วยการเดิมพันที่รวดเร็ว ระบบการจ่ายเงินรางวัลที่รวดเร็วที่สุด ระบบการฝาก-ถอนอัตโนมัติ พร้อมทีมงานดูแลซัพพอตตลอด 24 ชั่วโมง มาพร้อมเกมส์ใหม่ ระบบแนะนำสูงสุด 12% และโปรโมชั่นพิเศษมากมาย ภายใต้การบริหารโดยทีมงานมืออาชีพ ร่วมสนุกและทายผลไปกับเราได้ตั้งแต่วันนี้ ขอบคุณพระคุณครับ / ค่ะ&nbsp;
                  </marquee>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { BImg } from 'bootstrap-vue'

export default {
  components: {
    // Navbar Components
    BImg,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout')
    },
  },
}
</script>

<style scoped>
.container {
  max-width: 600px;
}
.pd {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: 13px;
  color: #fff;
}
.bg2 {
  background-color: #868686;
  padding: 2px;
  border-radius: 0;
}
.bg {
  background: #FFFFFF;
  /* background-color: #414141; */
  background-position: center bottom;
  /* border-radius: unset; */
  background-size: cover;
  position: relative;
  z-index: unset;
}
i {
  color: #ffc400;
}
/* .neon {
  margin-top: -12px;
  font-family: 'Helvethaica', sans-serif;

  border-radius: 5px;
  padding: 15px 10px;
} */
</style>
